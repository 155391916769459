<template>
  <div id="layout-topbar" class="layout-topbar">
    <button class="p-link layout-menu-button" @click="toggleMenu">
      <span class="pi pi-bars"></span>
    </button>
    <div class="layout-topbar-icons">
      <span>{{ status }}</span>

      <!-- <button class="p-link">
        <span class="layout-topbar-item-text">Events</span>
        <span class="layout-topbar-icon pi pi-calendar"></span>
        <span class="layout-topbar-badge">5</span>
      </button> -->
      <LanguageSelector class="p-link language-selector" v-model="localeId" :onlyLanguages="supportedLanguagesIds" compact hide-language-name />
      <!--<button class="p-link">
        <span class="layout-topbar-item-text">Settings</span>
        <span class="layout-topbar-icon pi pi-cog"></span>
      </button> -->
      <button class="p-link" @click="signOut">
        <span class="layout-topbar-item-text">{{$t('login.signOut')}}</span>
        <span class="layout-topbar-icon pi pi-sign-out"></span>
      </button>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore, mapMutations } from 'vuex'
import LanguageSelector from '@/components/resource/LanguageSelector.vue'
import useI18n, { supportedLanguages } from '@/compositions/useI18n'
import { useWsck } from '@/compositions'

export default {
  components: {
    LanguageSelector
  },
  setup () {
    const store = useStore()
    const { status } = useWsck()
    const { locale, setLocale } = useI18n()
    const languages = computed(() => (store.getters['resource/languages']))
    const localeId = computed({
      get: () => (languages.value.find((language) => language.iso_639_1 == locale.value) || {}).id,
      set: (value) => {
        const newLocale = languages.value.find((lang) => lang.id === value)
        setLocale(newLocale.iso_639_1)
      }
    })

    const supportedLanguagesIds = computed(() => supportedLanguages.map((lang) => {
      const supportedLang = languages.value.find((language) => language.iso_639_1 === lang)
      return supportedLang ? supportedLang.id : null
    }).filter((lang) => (!!lang)))

    return {
      status,
      supportedLanguagesIds,
      localeId,
      locale
    }
  },
  methods: {
    ...mapMutations('app', ['toggleMenu']),
    async signOut () {
      await this.$auth.logout()
      this.$router.push('/account/login')
    },
  }
}
</script>

<style lang="scss" scoped>
#layout-topbar {
  :deep(.language-selector) {
    .p-dropdown {
      background-color: transparent;
      border: none;
      &-trigger {
        color: #fff;
      }
    }
  }

}
</style>