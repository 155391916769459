<template>
	<div :class="containerClass" @click="clickOnWrapper">
		<AppTopBar />

    <transition name="layout-sidebar">
      <div :class="sidebarClass" @click="clickOnMenu" v-show="isSidebarVisible">
        <div class="layout-logo m-2">
          <router-link to="/">
            <img alt="Logo" :src="logo" style="width: 100%" />
          </router-link>
        </div>

        <AppProfile />
        <AppMenu :model="menu" @menuitem-click="clickOnMenuItem" />
      </div>
    </transition>

		<div class="layout-main">
      <div class="my-3">
        <Breadcrumb v-if="$breadcrumb.hasItems()" :home="$breadcrumb.getHome()" :model="$breadcrumb.getItems()" />
        <div class="p-d-flex p-jc-between p-ai-center main-breadcrumb flex justify-content-between">
          <div class="p-page-title">
            {{ hasTranslation(`navbar.${$breadcrumb.getTitle()}`) ? $t(`navbar.${$breadcrumb.getTitle()}`) : $breadcrumb.getTitle() }} 
            <Badge v-if="$breadcrumb.getBadge()" :value="$breadcrumb.getBadge()" size="large"></Badge>
            <div v-if="$breadcrumb.getCaption()" class="p-page-caption">
              {{ $breadcrumb.getCaption() }}
            </div>
          </div>
          <div id="title-action">
          </div>
        </div>
        <!-- <hr/> -->
      </div>
			<router-view :key="$route.fullPath" />
		</div>

		<!-- <AppConfig :layoutMode="layoutMode" :layoutColorMode="layoutColorMode" @layout-change="onLayoutChange" @layout-color-change="onLayoutColorChange"/> -->

		<AppFooter />
    <Toast />
    <ConfirmDialog />
	</div>
</template>

<script>
import Toast from 'primevue/toast'
import ConfirmDialog from 'primevue/confirmdialog'

import AppTopBar from './components/AppTopbar.vue'
import AppProfile from './components/AppProfile.vue'
import AppMenu from './components/AppMenu.vue'
// import AppConfig from './AppConfig.vue'
import AppFooter from './components/AppFooter.vue'

import Breadcrumb from 'primevue/breadcrumb';
import Badge from 'primevue/badge'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { useWsckSetup } from '@/compositions'
import { useBreakpoints } from '@vueuse/core'
import { useI18n } from 'vue-i18n'

export default {
  components: {
    AppTopBar, AppProfile, AppMenu, AppFooter, //AppConfig
    Breadcrumb, Toast, ConfirmDialog, Badge
  },
  setup () {
    const i18n = useI18n()
    useWsckSetup()
    const breakpoints = useBreakpoints({
      laptop: 1024
    })

    const hasTranslation = (path) => {
      return i18n.te(path)
    }

    const isDesktop = breakpoints.greater('laptop')
    return { isDesktop, hasTranslation }
  },
  watch: {
    $route() {
      this.resetMenuClick()
    },
    isDesktop (value) {
      this.setDesktopMode(value)
    }
  },
  methods: {
    ...mapMutations('app', ['clickOnWrapper', 'clickOnMenu', 'clickOnMenuItem', 'updateBodyOverflow', 'resetMenuClick', 'setDesktopMode']),
    ...mapActions('resource', ['initResource'])
  },
  computed: {
    ...mapGetters('app', ['isSidebarVisible', 'containerClass', 'sidebarClass', 'logo', 'menu']),
  },
  async mounted () {
    await this.initResource()
  },
  beforeUpdate () {
    this.updateBodyOverflow()
  }
}
</script>

<style lang="scss">
.p-toast.p-toast-top-right {
  z-index: 1000;
  top: 70px;
}

.comment {
  color:var(--surface-500)
}
</style>
